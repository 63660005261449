@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&family=Roboto:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-background text-primary font-body;
}

.nav-link {
  @apply relative text-primary font-display font-semibold text-lg hover:text-accent transition-colors duration-300;
}

.nav-link::after {
  @apply content-[''] bg-accent h-0.5 w-0 left-0 -bottom-1 rounded-full absolute transition-all duration-300;
}

.nav-link:hover::after {
  @apply w-full;
}

.hamburger {
  @apply w-6 h-6 flex items-center justify-center relative;
}

.hamburger span {
  @apply w-full h-0.5 bg-primary rounded-full transition-all duration-300 absolute;
}

.hamburger span:first-child {
  @apply -translate-y-2;
}

.hamburger span:last-child {
  @apply translate-y-2;
}

.hamburger.active span:first-child {
  @apply rotate-45 translate-y-0;
}

.hamburger.active span:nth-child(2) {
  @apply opacity-0;
}

.hamburger.active span:last-child {
  @apply -rotate-45 translate-y-0;
}